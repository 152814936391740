import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  getRoute,
  getRoute2,
  getRoute3,
} from "../../redux/reducers/uploadedRouteSlice";
import {
  getRouteName,
  getRouteName2,
  getRouteName3,
} from "../../redux/reducers/uploadedRouteSlice";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function MultipleRiskLineChart() {
  const dispatch = useAppDispatch();

  const route1 = useAppSelector(getRoute);
  const route2 = useAppSelector(getRoute2);
  const route3 = useAppSelector(getRoute3);

  const route1Name = useAppSelector(getRouteName);
  const route2Name = useAppSelector(getRouteName2);
  const route3Name = useAppSelector(getRouteName3);

  //grabbing vulnerability scores for all 3 routes, pushing into individual arrays
  var route1scores: any[] = [];
  console.log(route1);
  route1.features.forEach(function (value: any) {
    // console.log(value.properties.vulnerability_score);
    route1scores.push(value.properties.risk);
  });

  var route2scores: any[] = [];
  route2.features.forEach(function (value: any) {
    // console.log(value.properties.vulnerability_score);
    route2scores.push(value.properties.risk);
  });

  var route3scores: any[] = [];
  route3.features.forEach(function (value: any) {
    // console.log(value.properties.vulnerability_score);
    route3scores.push(value.properties.risk);
  });

  var dataArray = [];
  //getting length of longest route array
  var longestArray = Math.max(
    route1scores.length,
    route2scores.length,
    route3scores.length,
  );

  //filling the arrays to same size
  for (var i = route1scores.length; i < longestArray; i++) {
    route1scores.push(null);
  }
  for (var i = route2scores.length; i < longestArray; i++) {
    route2scores.push(null);
  }
  for (var i = route3scores.length; i < longestArray; i++) {
    route3scores.push(null);
  }

  // building chart data
  for (var i = 0; i < longestArray; i++) {
    dataArray.push({
      linkID: i,
      [route1Name]: route1scores[i],
      [route2Name]: route2scores[i],
      [route3Name]: route3scores[i],
    });
  }

  const divStyle = {
    height: 300,
    paddingLeft: "10%",
  };

  return (
    <div style={divStyle}>
      <ResponsiveContainer box-sizing="border-box" width="90%" debounce={1}>
        <LineChart data={dataArray}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="linkID" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey={route1Name} stroke="#82ca9d" />
          <Line type="monotone" dataKey={route2Name} stroke="#8884d8" />
          <Line type="monotone" dataKey={route3Name} stroke="#ff7300" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export { MultipleRiskLineChart };
