import { Component } from "react";
import { connect } from "react-redux";
import mapboxgl from "mapbox-gl";
import { MapboxSearchBox } from "@mapbox/search-js-web";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./Map.css";
import "./mapbox-gl.css";
import "./boxdraw.css";
import Button from "@material-ui/core/Button";
import { CustomControl } from "../../components/generateRouteCustomControl/GenerateRouteCustomControl";
import { WaypointDropper } from "../../components/waypointDropper/WaypointDropper";
import "mapbox-gl/dist/mapbox-gl.css";

import { setLinkIds } from "../../redux/reducers/highlightedRouteSlice";
import { setRoute } from "../../redux/reducers/uploadedRouteSlice";
import { setRoute2 } from "../../redux/reducers/uploadedRouteSlice";
import { setRoute3 } from "../../redux/reducers/uploadedRouteSlice";

import { selectLineStringCoordinates } from "../../redux/reducers/tstarSlice";

mapboxgl.accessToken =
  "pk.eyJ1IjoibXllcnNhdCIsImEiOiJjbGZ1MHBnc3cwNjloM2tydDJkbTd0ODIwIn0.dxdkK8vfc0XqniXIxt0kQA";

class Map extends Component {
  public mouseStart: any;
  public mouseCurrent: any;
  public boundingBox: any;
  public canvas: any;
  public map: any;
  public props: any;
  public mapStateToProps: any;
  public mapBounds: any;
  public view: any;
  public layers: any;

  constructor(props: any) {
    super(props);
    this.mapBounds = null;
    this.mouseStart = null;
    this.mouseCurrent = null;
    this.boundingBox = null;
    this.canvas = null;
    this.mousePos = this.mousePos.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.finish = this.finish.bind(this);
    this.view = "route";
    this.layers = [
      {
        id: "HIFLD_Hospitals",
        sourceLayer: "hifld.hospitals",
        tileFormat: "",
        type: "symbol",
        layout: {
          visibility: "none",
          "icon-image": "hospital-marker",
          "icon-size": 0.1,
          "text-field": ["get", "name"],
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1.25],
          "text-anchor": "top",
        },
        paint: {},
      },
      // Add the rest of the layers here
    ];
  }

  addSources(arrayOfSources: []): void {
    arrayOfSources.forEach((obj) => {
      const { id, type, sourceLayer, tileFormat, layout, paint } = obj;

      this.map.addSource(id, {
        type: "vector",
        tiles: [
          window.location.href +
            "proxy/" +
            sourceLayer +
            "/{z}/{x}/{y}" +
            tileFormat,
          // "http://localhost:7817/" + sourceLayer + "/{z}/{x}/{y}" + tileFormat
        ],
        minzoom: 0,
        maxzoom: 22,
      });

      this.map.addLayer({
        id: id,
        type: type,
        source: id,
        "source-layer": sourceLayer,
        layout: layout,
        paint: paint,
      });
    });
  }

  //saving this just in case new zoom breaks it
  // getBoundsFromFeatures = (features: any) => {
  //   var coords: any = [];
  //   for (let i = 0; i < features.length; i++) {
  //     coords = coords.concat(...features[i].geometry.coordinates);
  //   }
  //   var bounds = coords.reduce(
  //     function (bounds: any, coord: any) {
  //       return bounds.extend(coord);
  //     },
  //     new mapboxgl.LngLatBounds(coords[0], coords[0])
  //   );
  //   return bounds;
  // };

  getBoundsFromFeatures = (features: any) => {
    let coords: [number, number][] = [];
    features.forEach((feature: any) => {
      coords = coords.concat(feature.geometry.coordinates);
    });

    // Ensure coords has at least two points
    if (coords.length < 2) {
      throw new Error("Insufficient coordinates to calculate bounds.");
    }

    // Create bounds from coordinates
    const bounds = coords.reduce(
      (bounds: mapboxgl.LngLatBounds, coord: [number, number]) =>
        bounds.extend(coord),
      new mapboxgl.LngLatBounds(coords[0], coords[0])
    );

    return bounds;
  };

  zoomToBounds = () => {
    if (this.mapBounds) {
      this.map.fitBounds(this.mapBounds, { padding: 100 });
    }
  };

  toggleMapLayer = (layerName: any) => {
    if (
      layerName !== "route" &&
      layerName !== "route2" &&
      layerName !== "route3" &&
      layerName !== "LandscanUSA" &&
      layerName !== "LandscanGlobal"
    ) {
      document.getElementById("toggleBackdropOn")!.click();
    }

    if (layerName == "route") {
      if (this.view == "normalizedRoute") {
        layerName = "normalizedRoute";
      }
    }
    if (layerName == "route2") {
      if (this.view == "normalizedRoute") {
        layerName = "normalizedRoute2";
      }
    }
    if (layerName == "route3") {
      if (this.view == "normalizedRoute") {
        layerName = "normalizedRoute3";
      }
    }

    const visibility = this.map.getLayoutProperty(layerName, "visibility");
    if (visibility === "visible") {
      if (layerName == "LandscanGlobal") {
        document.getElementById("global-legend")!.style.display = "none";
      }
      if (layerName == "LandscanUSA") {
        document.getElementById("usa-legend")!.style.display = "none";
      }
      this.map.setLayoutProperty(layerName, "visibility", "none");
    } else {
      if (layerName == "LandscanGlobal") {
        document.getElementById("global-legend")!.style.display = "block";
      }
      if (layerName == "LandscanUSA") {
        document.getElementById("usa-legend")!.style.display = "block";
      }
      this.map.setLayoutProperty(layerName, "visibility", "visible");
    }
  };

  //changing data view between normalized and default
  changeView = (layerName: any) => {
    this.view = layerName;
    // console.log(layerName);
    if (this.view == "route") {
      if (this.props.routes.route1Checked) {
        this.map.setLayoutProperty("route", "visibility", "visible");
        this.map.setLayoutProperty("normalizedRoute", "visibility", "none");
      }
      if (this.props.routes.route2Checked) {
        this.map.setLayoutProperty("route2", "visibility", "visible");
        this.map.setLayoutProperty("normalizedRoute2", "visibility", "none");
      }
      if (this.props.routes.route3Checked) {
        this.map.setLayoutProperty("route3", "visibility", "visible");
        this.map.setLayoutProperty("normalizedRoute3", "visibility", "none");
      }
    }
    if (this.view == "normalizedRoute") {
      if (this.props.routes.route1Checked) {
        this.map.setLayoutProperty("normalizedRoute", "visibility", "visible");
        this.map.setLayoutProperty("route", "visibility", "none");
      }
      if (this.props.routes.route2Checked) {
        this.map.setLayoutProperty("normalizedRoute2", "visibility", "visible");
        this.map.setLayoutProperty("route2", "visibility", "none");
      }
      if (this.props.routes.route3Checked) {
        this.map.setLayoutProperty("normalizedRoute3", "visibility", "visible");
        this.map.setLayoutProperty("route3", "visibility", "none");
      }
    }
  };

  mousePos(e: any) {
    var rect = this.canvas.getBoundingClientRect();
    return new mapboxgl.Point(
      e.clientX - rect.left - this.canvas.clientLeft,
      e.clientY - rect.top - this.canvas.clientTop
    );
  }

  mouseDown(e: any) {
    if (!(e.shiftKey && e.button === 0)) return;

    // Disable default drag zooming when the shift key is held down.
    this.map.dragPan.disable();

    // Call functions for the following events
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("mouseup", this.onMouseUp);
    document.addEventListener("keydown", this.onKeyDown);

    this.mouseStart = this.mousePos(e);
  }

  onMouseMove(e: any) {
    this.mouseCurrent = this.mousePos(e);

    if (!this.boundingBox) {
      this.boundingBox = document.createElement("div");
      this.boundingBox.classList.add("boxdraw");
      this.canvas.appendChild(this.boundingBox);
    }

    var minX = Math.min(this.mouseStart.x, this.mouseCurrent.x),
      maxX = Math.max(this.mouseStart.x, this.mouseCurrent.x),
      minY = Math.min(this.mouseStart.y, this.mouseCurrent.y),
      maxY = Math.max(this.mouseStart.y, this.mouseCurrent.y);

    // Adjust width and xy position of the box element ongoing
    var pos = "translate(" + minX + "px," + minY + "px)";
    this.boundingBox.style.transform = pos;
    this.boundingBox.style.WebkitTransform = pos;
    this.boundingBox.style.width = maxX - minX + "px";
    this.boundingBox.style.height = maxY - minY + "px";
  }

  onMouseUp(e: any) {
    this.finish([this.mouseStart, this.mousePos(e)]);
  }

  onKeyDown(e: any) {
    // If the ESC key is pressed
    if (e.keyCode === 27) {
      this.finish();
    }
  }

  finish(highlightedBounds?: any) {
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("keydown", this.onKeyDown);
    document.removeEventListener("mouseup", this.onMouseUp);

    if (this.boundingBox) {
      this.boundingBox.parentNode.removeChild(this.boundingBox);
      this.boundingBox = null;
    }

    if (highlightedBounds) {
      var features = this.map.queryRenderedFeatures(highlightedBounds, {
        layers: ["route"],
      });

      // Run through the selected features and set a filter
      // to match features with unique linkids to activate
      // the `route-highlighted` layer.
      var filter = features.reduce(
        function (memo: any, feature: any) {
          memo.push(feature?.properties?.linkid);
          return memo;
        },
        ["in", "linkid"]
      );
      this.map.setFilter("route-highlighted", filter);

      var linkIds = features.map(({ properties }: any) => properties?.linkid);
      this.props.setLinkIds(linkIds);
    }

    this.map.dragPan.enable();
  }

  updateBaseLayer(layerName: any) {
    //only changing style if it's not the current style already
    let currentLayer = this.map.getStyle().sprite;
    currentLayer = currentLayer.substring(currentLayer.lastIndexOf("/") + 1);
    if (currentLayer != layerName) {
      this.map.setStyle("mapbox://styles/mapbox/" + layerName);
    }
  }

  //buttons clicked that prime the map to watch for users next click on the map for either start or destination
  watchForStartLocation = () => {
    this.map.location = "start";
  };
  watchForDestination = () => {
    this.map.location = "dest";
  };

  clearMarkers() {
    // let currentMarkers = this.map._markers;
    // for (var i = currentMarkers.length - 1; i >= 0; i--) {
    //   currentMarkers[i].remove();
    // }
  }

  resizeMap() {
    setTimeout(() => {
      this.map.resize();
    }, 500);
    setTimeout(() => {
      this.map.resize();
    }, 1000);
    setTimeout(() => {
      this.map.resize();
    }, 3000);
  }

  updateLineString = () => {
    if (this.map) {
      const routeSource = this.map.getSource("tstar_route");
      if (routeSource) {
        routeSource.setData({
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: this.props.lineStringCoordinates,
          },
        });

        // Update bounds and zoom
        try {
          this.mapBounds = this.getBoundsFromFeatures([
            {
              type: "Feature",
              geometry: {
                type: "LineString",
                coordinates: this.props.lineStringCoordinates,
              },
            },
          ]);
          this.zoomToBounds();
        } catch (error) {
          console.error("Error updating line string:", error);
        }
      }

      const finalPoint =
        this.props.lineStringCoordinates[
          this.props.lineStringCoordinates.length - 1
        ];

      // Remove existing final point layer and source if they exist
      if (this.map.getLayer("tstar_final_point")) {
        this.map.removeLayer("tstar_final_point");
      }

      if (this.map.getSource("tstar_final_point")) {
        this.map.removeSource("tstar_final_point");
      }

      // Add the new final point source and layer
      this.map.addSource("tstar_final_point", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: finalPoint,
          },
        },
      });

      this.map.addLayer({
        id: "tstar_final_point",
        type: "circle",
        source: "tstar_final_point",
        paint: {
          "circle-color": "#00A36C", // Green color for the dot
          "circle-radius": 7, // Radius of the dot
          "circle-stroke-color": "#000000", // Black color for the outline
          "circle-stroke-width": 2, // Width of the outline
        },
      });
    }
  };

  componentDidMount() {
    this.map = new mapboxgl.Map({
      container: "map-container",
      style: "mapbox://styles/mapbox/streets-v12",
      transformRequest: (url: string, resourceType: string): any => {
        let accessToken = this.props.token.token.token;
        if (resourceType === "Tile" && url.startsWith(window.location.href)) {
          console.log("map request with access token");
          return {
            url: url,
            headers: { Authorization: "Bearer " + accessToken },
          };
        }
      },
      center: [-97, 38],
      zoom: 4,
      attributionControl: false,
    });

    //Landscan Legends
    var globalLegend = document.getElementById("global-legend");
    this.map.getContainer().appendChild(globalLegend);
    var usaLegend = document.getElementById("usa-legend");
    this.map.getContainer().appendChild(usaLegend);
    var zoomLevel = document.getElementById("zoom-level");
    this.map.getContainer().appendChild(zoomLevel);

    this.map.boxZoom.disable();
    this.map.addControl(new mapboxgl.NavigationControl());
    this.map.addControl(new mapboxgl.AttributionControl());

    const search = new MapboxSearchBox();
    search.accessToken = mapboxgl.accessToken;
    // search.mapboxgl = this.map;
    // search.marker = false;
    search.popoverOptions = {
      placement: "top-start",
      flip: true,
      offset: 5,
    };

    search.options = {
      language: "en",
      country: "US",
      limit: 10,
    };

    //Can uncomment this if we want to clear markers when they start to search for anything else
    search.addEventListener("input", (even: any) => {
      this.clearMarkers();
    });

    search.addEventListener("retrieve", (event: any) => {
      this.clearMarkers();
      const featureCollection = event.detail;

      // Iterate through features within the collection
      featureCollection.features.forEach((feature: any) => {
        // Get the coordinates of the feature
        const coordinates = feature.geometry.coordinates;
        const lngLat = coordinates as mapboxgl.LngLatLike;

        let popupContent = `<h3>${feature.properties.name}</h3>`;
        // Check if 'full_address' property exists, if not use 'place_formatted'
        if (feature.properties.full_address) {
          popupContent += `<p>${feature.properties.full_address}</p>`;
        } else {
          popupContent += `<p>${feature.properties.place_formatted}</p>`;
        }

        // Create a marker for each feature and add it to the map
        new mapboxgl.Marker()
          .setLngLat(lngLat)
          .setPopup(new mapboxgl.Popup().setHTML(popupContent)) // Popup with feature name
          .addTo(this.map);
      });
    });

    // this.map.addControl(search, "top-left");

    // Add the control to the map.
    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      flyTo: false,
      placeholder: "Starting Location",
      routing: true,
      countries: "USA", //limit searches to USA only
    });

    // Add the control to the map.
    const geocoder2 = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl,
      flyTo: false,
      placeholder: "Destination",
      routing: true,
      countries: "USA", //limit searches to USA only
    });

    // Listen for the `result` event from the Geocoder
    // `result` event is triggered when a user makes a selection
    //  Add a marker at the result's coordinates
    geocoder.on("result", function (e) {
      console.log(e.result.geometry.coordinates); /*this line*/
      localStorage.setItem(
        "origin",
        JSON.stringify(e.result.geometry.coordinates)
      );
    });

    geocoder2.on("result", function (e) {
      console.log(e.result.geometry.coordinates); /*this line*/
      localStorage.setItem(
        "destination",
        JSON.stringify(e.result.geometry.coordinates)
      );
    });

    geocoder.on("clear", function () {
      localStorage.removeItem("origin");
    });

    geocoder2.on("clear", function () {
      localStorage.removeItem("destination");
    });

    this.map.addControl(geocoder, "top-left");
    this.map.addControl(geocoder2, "top-left");

    // Apply CSS styles to control the z-index of the geocoder containers
    const geocoderContainer = document.querySelector(".mapboxgl-ctrl-geocoder");
    const geocoderContainer2 = document.querySelectorAll(
      ".mapboxgl-ctrl-geocoder"
    )[1]; // Select the second geocoder container

    if (geocoderContainer instanceof HTMLElement) {
      geocoderContainer.style.zIndex = "1001"; // Set a higher z-index for the first geocoder
    }

    if (geocoderContainer2 instanceof HTMLElement) {
      geocoderContainer2.style.zIndex = "1000"; // Set a lower z-index for the second geocoder
    }

    // document.getElementById('geocoder')!.appendChild(geocoder.onAdd(this.map));
    // document.getElementById('geocoder2')!.appendChild(geocoder2.onAdd(this.map));

    // const customControl = new CustomControl();
    // this.map.addControl(customControl, "top-left");

    const waypointDropper = new WaypointDropper();
    this.map.addControl(waypointDropper, 'top-left');  

    //add markers on map for user generated route
    var startMarker = new mapboxgl.Marker();
    var destinationMarker = new mapboxgl.Marker();

    startMarker.getElement().addEventListener("click", () => {
      startMarker.remove();
    });
    destinationMarker.getElement().addEventListener("click", () => {
      destinationMarker.remove();
    });

    //setting coordinates on click for user generated routing
    function grabCoordinates(this: any, event: any) {
      let coordinates = event.lngLat;
      let lng = coordinates.lng;
      let lat = coordinates.lat;
      let lngLat = [lng, lat];
      if (this.location == "start") {
        (document.getElementById("start-location") as HTMLInputElement).value =
          lng + ", " + lat;
        startMarker.setLngLat(coordinates).addTo(this);
      }
      if (this.location == "dest") {
        (document.getElementById("destination") as HTMLInputElement).value =
          lng + ", " + lat;
        destinationMarker.setLngLat(coordinates).addTo(this);
      }
      this.location = null;
    }

    this.map.on("click", grabCoordinates);

    this.map.on("zoom", () => {
      var currentZoom = this.map.getZoom();
      // console.log(currentZoom);
      var roundedZoom = Math.round(currentZoom);
      document.getElementById("zoom-level")!.innerHTML =
        "Zoom Level: " + roundedZoom;
    });

    //hide loading screen whenever map is idle
    this.map.on("idle", () => {
      if (document.getElementById("toggleBackdropOff")) {
        document.getElementById("toggleBackdropOff")!.click();
      }
    });

    this.map.on("load", function () {
      // Map has finished loading, you can perform actions here
      if (document.getElementById("toggleBackdropOff")) {
        document.getElementById("toggleBackdropOff")!.click();
      }
    });

    //START LIVE DATA
    // this.map.on("load", async () => {
    //   // Get the initial location of the International Space Station (ISS).
    //   const geojson = await getLocation(this.map, null);
    //   // Add the ISS location as a source.
    //   this.map.addSource("iss", {
    //     type: "geojson",
    //     data: geojson,
    //   });
    //   // Add the rocket symbol layer to the map.
    //   this.map.addLayer({
    //     id: "iss",
    //     type: "symbol",
    //     source: "iss",
    //     layout: {
    //       // This icon is a part of the Mapbox Streets style.
    //       // To view all images available in a Mapbox style, open
    //       // the style in Mapbox Studio and click the "Images" tab.
    //       // To add a new image to the style at runtime see
    //       // https://docs.mapbox.com/mapbox-gl-js/example/add-image/
    //       "icon-image": "truck-marker",
    //     },
    //   });

    //   // Update the source from the API every 2 seconds.
    //   const updateSource = setInterval(async () => {
    //     const geojson = await getLocation(this.map, updateSource);
    //     this.map.getSource("iss").setData(geojson);
    //   }, 2000);

    //   async function getLocation(map: any, updateSource: any) {
    //     // Make a GET request to the API and return the location of the ISS.
    //     try {
    //       const response = await fetch(
    //         "https://api.wheretheiss.at/v1/satellites/25544",
    //         { method: "GET" }
    //       );
    //       const { latitude, longitude } = await response.json();
    //       // Fly the map to the location.
    //       map.flyTo({
    //         center: [longitude, latitude],
    //         speed: 0.5,
    //       });
    //       // Return the location of the ISS as GeoJSON.
    //       return {
    //         type: "FeatureCollection",
    //         features: [
    //           {
    //             type: "Feature",
    //             geometry: {
    //               type: "Point",
    //               coordinates: [longitude, latitude],
    //             },
    //           },
    //         ],
    //       };
    //     } catch (err) {
    //       // If the updateSource interval is defined, clear the interval to stop updating the source.
    //       if (updateSource) clearInterval(updateSource);
    //       // throw new Error(err);
    //     }
    //   }
    // });
    //END LIVE DATA

    //this will all be called anytime the maps style (base layer) is changed
    this.map.on("style.load", () => {
      this.canvas = this.map.getCanvasContainer();
      this.canvas.addEventListener("mousedown", this.mouseDown, true);

      this.map!.addSource("tstar_route", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "LineString",
            coordinates: this.props.lineStringCoordinates,
          },
        },
      });

      this.map!.addLayer({
        id: "tstar_route",
        type: "line",
        source: "tstar_route",
        layout: {},
        paint: {
          "line-color": "#00A36C",
          "line-width": 5,
        },
      });

      // Add a source for the final point
      const finalPoint =
        this.props.lineStringCoordinates[
          this.props.lineStringCoordinates.length - 1
        ];
      this.map!.addSource("tstar_final_point", {
        type: "geojson",
        data: {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: finalPoint,
          },
        },
      });

      // Add a layer for the final point with a large dot
      this.map.addLayer({
        id: "tstar_final_point",
        type: "circle",
        source: "tstar_final_point",
        paint: {
          "circle-color": "#00A36C", // Green color for the dot
          "circle-radius": 7, // Radius of the dot
          "circle-stroke-color": "#000000", // Black color for the outline
          "circle-stroke-width": 2, // Width of the outline
        },
      });

      // Landscan USA
      this.map.addSource("LandscanUSA", {
        type: "raster",
        tiles: [window.location.href + "proxyls/{z}/{x}/{y}.png"],
        scheme: "tms",
        tileSize: 256,
        minzoom: 0,
        maxzoom: 11,
      });
      this.map.addLayer({
        id: "LandscanUSA",
        type: "raster",
        source: "LandscanUSA",
        layout: {
          visibility: "none",
        },
        paint: {
          "raster-opacity": 0.5,
        },
      });

      // Landscan Global
      this.map.addSource("LandscanGlobal", {
        type: "raster",
        tiles: [window.location.href + "proxyls/2022/{z}/{x}/{y}.png"],
        scheme: "tms",
        tileSize: 256,
        minzoom: 0,
        maxzoom: 8,
      });
      this.map.addLayer({
        id: "LandscanGlobal",
        type: "raster",
        source: "LandscanGlobal",
        layout: {
          visibility: "none",
        },
        paint: {
          "raster-opacity": 0.5,
        },
      });

      // Cell Coverage (Any Provider)
      this.map.addSource("Cell_Coverage", {
        type: "vector",
        tiles: [
          window.location.href +
            "proxy/cell_data.no_cell_coverage_exw_dumped/{z}/{x}/{y}",
        ],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "Cell_Coverage",
        type: "fill",
        source: "Cell_Coverage",
        "source-layer": "cell_data.no_cell_coverage_exw_dumped",
        layout: {
          visibility: "none",
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top'
        },
        paint: {
          "fill-color": "#f94144",
          "fill-outline-color": "#000000",
          "fill-opacity": 0.8,
        },
      });

      // Cell Coverage (AT&T)
      this.map.addSource("Cell_Coverage_ATT", {
        type: "vector",
        tiles: [
          window.location.href +
            "proxy/cell_data.no_att_coverage_cnty_exwater/{z}/{x}/{y}",
        ],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "Cell_Coverage_ATT",
        type: "fill",
        source: "Cell_Coverage_ATT",
        "source-layer": "cell_data.no_att_coverage_cnty_exwater",
        layout: {
          visibility: "none",
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top'
        },
        paint: {
          "fill-color": "#c44536",
          "fill-outline-color": "#000000",
          "fill-opacity": 0.8,
        },
      });

      // Cell Coverage (US Cellular)
      this.map.addSource("Cell_Coverage_US_Cellular", {
        type: "vector",
        tiles: [
          window.location.href +
            "proxy/cell_data.no_usc_coverage_cnty_exwater/{z}/{x}/{y}",
        ],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "Cell_Coverage_US_Cellular",
        type: "fill",
        source: "Cell_Coverage_US_Cellular",
        "source-layer": "cell_data.no_usc_coverage_cnty_exwater",
        layout: {
          visibility: "none",
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top'
        },
        paint: {
          "fill-color": "#f9c74f",
          "fill-outline-color": "#000000",
          "fill-opacity": 0.8,
        },
      });

      // Cell Coverage (T Mobile)
      this.map.addSource("Cell_Coverage_T_Mobile", {
        type: "vector",
        tiles: [
          window.location.href +
            "proxy/cell_data.no_tmo_coverage_cnty_exwater/{z}/{x}/{y}",
        ],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "Cell_Coverage_T_Mobile",
        type: "fill",
        source: "Cell_Coverage_T_Mobile",
        "source-layer": "cell_data.no_tmo_coverage_cnty_exwater",
        layout: {
          visibility: "none",
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top'
        },
        paint: {
          "fill-color": "#f8961e",
          "fill-outline-color": "#000000",
          "fill-opacity": 0.7,
        },
      });

      // Cell Coverage (Verizon)
      this.map.addSource("Cell_Coverage_Verizon", {
        type: "vector",
        tiles: [
          window.location.href +
            "proxy/cell_data.no_vez_coverage_cnty_exwater/{z}/{x}/{y}",
        ],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "Cell_Coverage_Verizon",
        type: "fill",
        source: "Cell_Coverage_Verizon",
        "source-layer": "cell_data.no_vez_coverage_cnty_exwater",
        layout: {
          visibility: "none",
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top'
        },
        paint: {
          "fill-color": "#90be6d",
          "fill-outline-color": "#000000",
          "fill-opacity": 0.8,
        },
      });

      // HIFLD Hospitals
      this.map.addSource("HIFLD_Hospitals", {
        type: "vector",
        tiles: [
          window.location.href + "proxy/hifld.hospitals/{z}/{x}/{y}", //was trying it hard coded this way before proxy //was trying it hard coded this way before proxy
        ],
        minzoom: 0,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_Hospitals",
        type: "symbol",
        source: "HIFLD_Hospitals",
        "source-layer": "hifld.hospitals",
        layout: {
          visibility: "none",
          "icon-image": "hospital-marker",
          "icon-size": 0.04,
          // get the hospital name
          // "text-field": ["get", "name"],
          // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          // "text-offset": [0, 1.25],
          // "text-anchor": "top",
        },
      });

      // HIFLD Police
      this.map.addSource("HIFLD_Police", {
        type: "vector",
        tiles: [
          window.location.href + "proxy/hifld.police/{z}/{x}/{y}", //was trying it hard coded this way before proxy //was trying it hard coded this way before proxy
        ],
        minzoom: 0,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_Police",
        type: "symbol",
        source: "HIFLD_Police",
        "source-layer": "hifld.police",
        layout: {
          visibility: "none",
          "icon-image": "police-marker",
          "icon-size": 0.1,
          // get the hospital name
          // "text-field": ["get", "name"],
          // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          // "text-offset": [0, 1.25],
          // "text-anchor": "top",
        },
      });

      // HIFLD EMS
      this.map.addSource("HIFLD_EMS", {
        type: "vector",
        tiles: [window.location.href + "proxy/hifld.ems/{z}/{x}/{y}"],
        minzoom: 0,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_EMS",
        type: "symbol",
        source: "HIFLD_EMS",
        "source-layer": "hifld.ems",
        layout: {
          visibility: "none",
          "icon-image": "EMS-marker",
          "icon-size": 0.015,
          // get the hospital name
          // "text-field": ["get", "name"],
          // "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          // "text-offset": [0, 1.25],
          // "text-anchor": "top",
        },
      });

      // HIFLD Weigh Stations
      this.map.addSource("HIFLD_Weigh_Stations", {
        type: "vector",
        tiles: [
          window.location.href + "proxy/hifld.weigh_stations/{z}/{x}/{y}", //was trying it hard coded this way before proxy
        ],
        minzoom: 0,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_Weigh_Stations",
        type: "circle",
        source: "HIFLD_Weigh_Stations",
        "source-layer": "hifld.weigh_stations",
        layout: {
          visibility: "none",
          // "icon-image": "blue-marker",
        },
        paint: {
          "circle-radius": 3,
          "circle-color": "#004e98",
          "circle-stroke-width": 1,
          "circle-stroke-color": "black",
        },
      });

      // HIFLD Tunnels
      this.map.addSource("HIFLD_Tunnels", {
        type: "vector",
        tiles: [window.location.href + "proxy/hifld.tunnels/{z}/{x}/{y}"],
        minzoom: 0,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_Tunnels",
        type: "circle",
        source: "HIFLD_Tunnels",
        "source-layer": "hifld.tunnels",
        layout: {
          visibility: "none",
          //TODO: we probably want to come back to this and make it multiLineString instead of a point
          // "icon-image": "green-marker",
        },
        paint: {
          "circle-radius": 3,
          "circle-color": "#43aa8b",
          "circle-stroke-width": 1,
          "circle-stroke-color": "black",
        },
      });

      // HIFLD Rail Junctions
      this.map.addSource("HIFLD_Rail_Juntions", {
        type: "vector",
        tiles: [
          window.location.href + "proxy/hifld.rail_junctions/{z}/{x}/{y}",
        ],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_Rail_Juntions",
        type: "circle",
        source: "HIFLD_Rail_Juntions",
        "source-layer": "hifld.rail_junctions",
        layout: {
          visibility: "none",
          //TODO: we probably want to come back to this and make it multiLineString instead of a point
          // "icon-image": "purple-marker",
        },
        paint: {
          "circle-radius": 3,
          "circle-color": "#6a4c93",
          "circle-stroke-width": 1,
          "circle-stroke-color": "black",
        },
      });

      // HIFLD Bridges
      this.map.addSource("HIFLD_Bridges", {
        type: "vector",
        tiles: [window.location.href + "proxy/hifld.bridges/{z}/{x}/{y}"],
        minzoom: 7,
        maxzoom: 22,
      });
      this.map.addLayer({
        id: "HIFLD_Bridges",
        type: "circle",
        source: "HIFLD_Bridges",
        "source-layer": "hifld.bridges",
        layout: {
          visibility: "none",
          //TODO: we probably want to come back to this and make it multiLineString instead of a point
          // "icon-image": "red-marker",
        },
        // tolerance: 0,
        paint: {
          "circle-radius": 3,
          "circle-color": "#f94144",
          "circle-stroke-width": 1,
          "circle-stroke-color": "black",
        },
      });

      // // NWS Current Watches
      this.map.addSource("NWS_Current_Watches", {
        type: "geojson",
        // data: 'https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/watch_warn_adv/MapServer/1/query?where=1%3D1&outFields=*&returnGeometry=true&f=geojson'
        data: "https://mapservices.weather.noaa.gov/eventdriven/rest/services/WWA/watch_warn_adv/MapServer/1/query?where=1%3D1&outFields=*&returnGeometry=true&f=geojson",
      });
      this.map.addLayer({
        id: "NWS_Current_Watches",
        type: "fill",
        source: "NWS_Current_Watches",
        paint: {
          "fill-color": "#004e98",
          "fill-opacity": 0.8,
        },
        layout: {
          // Make the layer hidden by default.
          visibility: "none",
        },
      });

      // Mapbox Traffic
      this.map.addSource("Mapbox_Traffic", {
        type: "vector",
        url: "mapbox://mapbox.mapbox-traffic-v1",
      });
      this.map.addLayer({
        id: "Mapbox_Traffic",
        type: "line",
        source: "Mapbox_Traffic",
        "source-layer": "traffic",
        paint: {
          "line-width": 3,
          "line-color": [
            "case",
            ["==", "low", ["get", "congestion"]],
            "#06d6a0",
            ["==", "moderate", ["get", "congestion"]],
            "#ffd166",
            ["==", "heavy", ["get", "congestion"]],
            "#ff9770",
            ["==", "severe", ["get", "congestion"]],
            "#ef476f",
            "#000000",
          ],
        },
        layout: {
          // Make the layer hidden by default.
          visibility: "none",
        },
      });

      // NWS Current Warnings
      this.map.addSource("NWS_Current_Warnings", {
        type: "geojson",
        // data: 'https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Forecasts_Guidance_Warnings/watch_warn_adv/MapServer/0/query?where=1%3D1&outFields=*&returnGeometry=true&f=geojson'
        data: "https://mapservices.weather.noaa.gov/eventdriven/rest/services/WWA/watch_warn_adv/MapServer/0/query?where=1%3D1&outFields=*&returnGeometry=true&f=geojson",
      });
      this.map.addLayer({
        id: "NWS_Current_Warnings",
        type: "fill",
        source: "NWS_Current_Warnings",
        paint: {
          "fill-color": "#43aa8b",
          "fill-opacity": 1,
        },
        layout: {
          // Make the layer hidden by default.
          visibility: "none",
        },
      });

      // NWS Wildfires
      this.map.addSource("NWS_Wildfires", {
        type: "geojson",
        // data: 'https://services3.arcgis.com/T4QMLg3qTGWY/ArcGIS/rest/services/Current_WildlandFire_Perimeters/FeatureServer/0/query?where=1%3D1&outFields=*&returnGeometry=true&f=geojson'
        data: "https://mapservices.weather.noaa.gov/vector/rest/services/fire_weather/SPC_firewx/MapServer/2/query?where=1%3D1&outFields=*&returnGeometry=true&f=geojson",
      });
      this.map.addLayer({
        id: "NWS_Wildfires",
        type: "fill",
        source: "NWS_Wildfires",
        paint: {
          "fill-color": "#6a4c93",
          "fill-opacity": 0.8,
        },
        layout: {
          // Make the layer hidden by default.
          visibility: "none",
        },
      });

      // NWS Weather Radar
      this.map.addSource("NWS_Weather_Radar", {
        type: "raster",
        // 'tiles': ['https://idpgis.ncep.noaa.gov/arcgis/rest/services/NWS_Observations/radar_base_reflectivity/MapServer/export?bbox={bbox-epsg-3857}=&layers=3&format=png&transparent=true&f=image']
        tiles: [
          "https://mapservices.weather.noaa.gov/eventdriven/rest/services/radar/radar_base_reflectivity/MapServer/export?bbox={bbox-epsg-3857}=&layers=3&format=png&transparent=true&f=image",
        ],
      });
      this.map.addLayer(
        {
          id: "NWS_Weather_Radar",
          type: "raster",
          source: "NWS_Weather_Radar",
          paint: { "raster-opacity": 0.8 },
          layout: {
            // Make the layer hidden by default.
            visibility: "none",
          },
        }
        // 'aeroway-line'
      );

      //hospital marker
      this.map.loadImage(
        // 'https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png',
        "https://cdn3.iconfinder.com/data/icons/medical-3-1/512/hospital_place-512.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("hospital-marker", image);
        }
      );

      //truck marker
      this.map.loadImage("/truck.png", (error: any, image: any) => {
        if (error) throw error;
        this.map.addImage("truck-marker", image);
      });

      //basic marker
      this.map.loadImage(
        "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("custom-marker", image);
        }
      );

      //blue marker
      this.map.loadImage(
        "/mapbox-marker-icon-20px-blue.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("blue-marker", image);
        }
      );

      //green marker
      this.map.loadImage(
        "/mapbox-marker-icon-20px-green.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("green-marker", image);
        }
      );

      //red marker
      this.map.loadImage(
        "/mapbox-marker-icon-20px-red.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("red-marker", image);
        }
      );

      //purple marker
      this.map.loadImage(
        "/mapbox-marker-icon-20px-purple.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("purple-marker", image);
        }
      );

      //police marker
      this.map.loadImage(
        "https://static.thenounproject.com/png/1661277-200.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("police-marker", image);
        }
      );

      //EMS marker
      this.map.loadImage(
        "https://cdn4.iconfinder.com/data/icons/health-medicine-volume-i/128/ems-symbol-1024.png",
        (error: any, image: any) => {
          if (error) throw error;
          this.map.addImage("EMS-marker", image);
        }
      );

      //popup stuff
      // Create a popup, but don't add it to the map yet.
      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        maxWidth: "500px",
      });

      //Hospitals
      this.map.on("mouseenter", "HIFLD_Hospitals", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description =
          "<strong>Name: </strong>" +
          e.features[0].properties.name +
          "<br><strong>Address: </strong>" +
          e.features[0].properties.address +
          "<br><strong>City: </strong>" +
          e.features[0].properties.city +
          "<br><strong>State: </strong>" +
          e.features[0].properties.state +
          "<br><strong>Zip: </strong>" +
          e.features[0].properties.zip +
          "<br><strong>Type: </strong>" +
          e.features[0].properties.type +
          "<br><strong>Status: </strong>" +
          e.features[0].properties.status;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_Hospitals", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //Police
      this.map.on("mouseenter", "HIFLD_Police", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description =
          "<strong>Name: </strong>" +
          e.features[0].properties.name +
          "<br><strong>Address: </strong>" +
          e.features[0].properties.address +
          "<br><strong>City: </strong>" +
          e.features[0].properties.city +
          "<br><strong>State: </strong>" +
          e.features[0].properties.state +
          "<br><strong>Zip: </strong>" +
          e.features[0].properties.zip +
          "<br><strong>Type: </strong>" +
          e.features[0].properties.type +
          "<br><strong>Status: </strong>" +
          e.features[0].properties.status;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_Police", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //Weigh Stations
      this.map.on("mouseenter", "HIFLD_Weigh_Stations", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description =
          "<strong>FID: </strong>" +
          e.features[0].properties.fid +
          "<br><strong>Location: </strong>" +
          e.features[0].properties.location +
          "<br><strong>Per Truck: </strong>" +
          e.features[0].properties.pertruck +
          "<br><strong>Comment: </strong>" +
          e.features[0].properties.comment;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_Weigh_Stations", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //EMS
      this.map.on("mouseenter", "HIFLD_EMS", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";

        const coordinates = e.features[0].geometry.coordinates.slice();
        const description =
          "<strong>Name: </strong>" +
          e.features[0].properties.name +
          "<br><strong>Address: </strong>" +
          e.features[0].properties.address +
          "<br><strong>City: </strong>" +
          e.features[0].properties.city +
          "<br><strong>State: </strong>" +
          e.features[0].properties.state +
          "<br><strong>Zip: </strong>" +
          e.features[0].properties.zip +
          "<br><strong>Specialty: </strong>" +
          e.features[0].properties.specialty +
          "<br><strong>Level: </strong>" +
          e.features[0].properties.level_;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_EMS", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //Tunnels
      this.map.on("mouseenter", "HIFLD_Tunnels", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";
        console.log(e.features[0]);

        const coordinates = e.features[0].geometry.coordinates[0];
        const description =
          "<strong>Name: </strong>" +
          e.features[0].properties.name +
          "<br><strong>Address: </strong>" +
          e.features[0].properties.address +
          "<br><strong>State: </strong>" +
          e.features[0].properties.state +
          "<br><strong>Description: </strong>" +
          e.features[0].properties.naicsdescr;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_Tunnels", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //Rail Junction
      this.map.on("mouseenter", "HIFLD_Rail_Juntions", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";

        const coordinates = e.lngLat;
        const description =
          "<strong>OGC FID: </strong>" +
          e.features[0].properties.ogc_fid +
          "<br><strong>State: </strong>" +
          e.features[0].properties.state +
          "<br><strong>Country Code: </strong>" +
          e.features[0].properties.country +
          "<br><strong>FRA Region: </strong>" +
          e.features[0].properties.fraregion +
          "<br><strong>Boundary Code: </strong>" +
          e.features[0].properties.bndry;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_Rail_Juntions", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //Bridges
      this.map.on("mouseenter", "HIFLD_Bridges", (e: any) => {
        this.map.getCanvas().style.cursor = "pointer";
        console.log(e.features[0]);

        // Structure Number: "structure_number_008": "B 646" AS IS
        // Route Carried: "facility_carried_007": "US 50" AS IS
        // Under Bridge Service Type:  "service_und_042b": "5" MAPPING (1="highway", 2="railroad", 3="pedestrian-bicycle", 4="highway-railroad", 5="waterway", 6="highway-waterway", 7="railroad-waterway", 8="highway-waterway-railroad", 9="relief for waterway", 0="other-non-highway")
        // Maximum Permissible Load Level: 064-"operating_rating_064": 45.2 TWO CONDITIONALS, ADD UNITS (0="should be closed", 99.9="structure under fill, not affected by live load") ELSE AS IS + "metric tons"
        // Total Horizontal Clearance: "horr_clr_mt_047": 9.8 (99.9="100 meters or greater") ELSE AS IS + "meters"
        // Structure Length: "structure_len_mt_049": 9.8 AS IS + "meters"
        // Vertical Bridge Clearance Over Roadway: "vert_clr_over_mt_053": 99.99 (99.99="30 meters or greater") ELSE AS IS +"meters"
        // Year Built:  "year_built_027": 1955  AS IS
        // Bypass Detour Length: "detour_kilos_019": 124 AS IS + "kilometers"
        // Structure Load Design: "design_load_031": "5" MAPPING (1="H 10", 2="H 15", 3="HS 13.5", 4="H 20", 5="HS 20", 6 ="HS 20+Mod", 7="Pedestrian", 8="Railroad", 9="HS 25 or greater", 0="unknown", A="HL 93", B="Greater than HL 93", C="other")

        //formatting under bridge service type
        let service_und_042b = e.features[0].properties.service_und_042b;
        const underBridgeServiceType =
          service_und_042b == 1
            ? "highway"
            : service_und_042b == 2
            ? "railroad"
            : service_und_042b == 3
            ? "pedestrian-bicycle"
            : service_und_042b == 4
            ? "highway-railroad"
            : service_und_042b == 5
            ? "waterway"
            : service_und_042b == 6
            ? "highway-waterway"
            : service_und_042b == 7
            ? "railroad-waterway"
            : service_und_042b == 8
            ? "highway-waterway-railroad"
            : service_und_042b == 9
            ? "relief for waterway"
            : service_und_042b == 0
            ? "other-non-highway"
            : "Unknown value";

        //formatting maximum permissible load level
        let operating_rating_064 =
          e.features[0].properties.operating_rating_064;
        const maximumPermissibleLoadLevel =
          operating_rating_064 == 0
            ? "should be closed"
            : operating_rating_064 == 99.9
            ? "structure under fill, not affected by live load"
            : operating_rating_064 + " metric tons";

        //formatting total horizontal clearance
        let horr_clr_mt_047 = e.features[0].properties.horr_clr_mt_047;
        const totalHorizontalClearance =
          horr_clr_mt_047 == 99.9
            ? "100 meters or greater"
            : horr_clr_mt_047 + " meters";

        //formatting vertical bridge clearance over roadway
        let vert_clr_over_mt_053 =
          e.features[0].properties.vert_clr_over_mt_053;
        const verticalBridgeClearanceOverRoadway =
          vert_clr_over_mt_053 == 99.99
            ? "30 meters or greater"
            : vert_clr_over_mt_053 + " meters";

        //formatting structure load design
        let design_load_031 = e.features[0].properties.design_load_031;
        const structureLoadDesign =
          design_load_031 == 1
            ? "H 10"
            : design_load_031 == 2
            ? "H 15"
            : design_load_031 == 3
            ? "HS 13.5"
            : design_load_031 == 4
            ? "H 20"
            : design_load_031 == 5
            ? "HS 20"
            : design_load_031 == 6
            ? "HS 20+Mod"
            : design_load_031 == 7
            ? "Pedestrian"
            : design_load_031 == 8
            ? "Railroad"
            : design_load_031 == 9
            ? "HS 25 or greater"
            : design_load_031 == 0
            ? "unknown"
            : design_load_031 == "A"
            ? "HL 93"
            : design_load_031 == "B"
            ? "Greater than HL 93"
            : design_load_031 == "C"
            ? "other"
            : "Unknown value";

        const coordinates = e.lngLat;
        const description =
          "<strong>Structure Number: </strong>" +
          e.features[0].properties.structure_number_008 +
          "<br><strong>Route Carried: </strong>" +
          e.features[0].properties.facility_carried_007 +
          "<br><strong>Under Bridge Service Type: </strong>" +
          underBridgeServiceType +
          "<br><strong>Maximum Permissible Load Level: </strong>" +
          maximumPermissibleLoadLevel +
          "<br><strong>Total Horizontal Clearance: </strong>" +
          totalHorizontalClearance +
          "<br><strong>Structure Length: </strong>" +
          e.features[0].properties.structure_len_mt_049 +
          " meters" +
          "<br><strong>Vertical Bridge Clearance Over Roadway: </strong>" +
          verticalBridgeClearanceOverRoadway +
          "<br><strong>Year Built: </strong>" +
          e.features[0].properties.year_built_027 +
          "<br><strong>Bypass Detour Length: </strong>" +
          e.features[0].properties.detour_kilos_019 +
          " kilometers" +
          "<br><strong>Structure Load Design: </strong>" +
          structureLoadDesign;

        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
      });
      this.map.on("mouseleave", "HIFLD_Bridges", () => {
        this.map.getCanvas().style.cursor = "";
        popup.remove();
      });

      //re-adding the loaded route if map base layer (style) is changed
      if (this.props.route.route) {
        //this is hard coded so we don't load the default test route onto map
        if (this.props.route.route.features[0].properties.id != 999999999999) {
          this.addRoute(this.props.route.route);
        }
      }
      if (this.props.route.route2) {
        //this is hard coded so we don't load the default test route onto map
        if (this.props.route.route2.features[0].properties.id != 999999999999) {
          this.addRoute2(this.props.route.route2);
        }
      }
      if (this.props.route.route3) {
        //this is hard coded so we don't load the default test route onto map
        if (this.props.route.route3.features[0].properties.id != 999999999999) {
          this.addRoute3(this.props.route.route3);
        }
      }

      // We could use something like this to reduce code repetition
      // Identify layer that we're hovering over and dispaly popover according to that layer layerName
      // this currently crashes sometimes though, still need to troubleshoot
      // this.map.on('mousemove', (e: any) => {
      //     const features = this.map.queryRenderedFeatures(e.point);
      //     if(features){
      //         console.log(features[0].layer);
      //         if layer.id == whatever, then display accordingly
      //     }
      // });
    });
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.props.lineStringCoordinates !== prevProps.lineStringCoordinates) {
      this.updateLineString();
    }
    // var filter = this.props.linkIds.reduce(
    //     function(memo: any, linkId: any) {
    //         memo.push(linkId);
    //         return memo;
    //     },
    //     ['in', 'linkid']
    // );
    // this.map.setFilter('route-highlighted', filter);

    //if "route" changes, call an update and add it to the map
    if (this.props.route.route !== prevProps.route.route) {
      console.log("adding route 1");
      if (this.props.route.route.features[0].properties.id != 999999999999) {
        this.addRoute(this.props.route.route);
      }
    }
    if (this.props.route.route2 !== prevProps.route.route2) {
      console.log("adding route 2");
      if (this.props.route.route2.features[0].properties.id != 999999999999) {
        this.addRoute2(this.props.route.route2);
      }
    }
    if (this.props.route.route3 !== prevProps.route.route3) {
      if (this.props.route.route3.features[0].properties.id != 999999999999) {
        this.addRoute3(this.props.route.route3);
      }
      console.log("adding route 3");
    }
  }

  render() {
    return (
      <div>
        <div id="map-container" />
        <div id="global-legend" style={{ display: "none" }}></div>
        <div id="usa-legend" style={{ display: "none" }}></div>
        <div id="zoom-level">Zoom Level: 4</div>
        <Button
          style={{ visibility: "hidden" }}
          id="LandscanUSA"
          color="inherit"
          onClick={() => this.toggleMapLayer("LandscanUSA")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Mapbox_Traffic"
          color="inherit"
          onClick={() => this.toggleMapLayer("Mapbox_Traffic")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="LandscanGlobal"
          color="inherit"
          onClick={() => this.toggleMapLayer("LandscanGlobal")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Cell_Coverage"
          color="inherit"
          onClick={() => this.toggleMapLayer("Cell_Coverage")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Cell_Coverage_ATT"
          color="inherit"
          onClick={() => this.toggleMapLayer("Cell_Coverage_ATT")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Cell_Coverage_T_Mobile"
          color="inherit"
          onClick={() => this.toggleMapLayer("Cell_Coverage_T_Mobile")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Cell_Coverage_US_Cellular"
          color="inherit"
          onClick={() => this.toggleMapLayer("Cell_Coverage_US_Cellular")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Cell_Coverage_Verizon"
          color="inherit"
          onClick={() => this.toggleMapLayer("Cell_Coverage_Verizon")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="NWS_Current_Watches"
          color="inherit"
          onClick={() => this.toggleMapLayer("NWS_Current_Watches")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="NWS_Current_Warnings"
          color="inherit"
          onClick={() => this.toggleMapLayer("NWS_Current_Warnings")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="NWS_Wildfires"
          color="inherit"
          onClick={() => this.toggleMapLayer("NWS_Wildfires")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="NWS_Weather_Radar"
          color="inherit"
          onClick={() => this.toggleMapLayer("NWS_Weather_Radar")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_Hospitals"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_Hospitals")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_Police"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_Police")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_Weigh_Stations"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_Weigh_Stations")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_EMS"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_EMS")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_Tunnels"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_Tunnels")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_Rail_Juntions"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_Rail_Juntions")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="HIFLD_Bridges"
          color="inherit"
          onClick={() => this.toggleMapLayer("HIFLD_Bridges")}
        ></Button>
        {/*<Button style={{visibility: 'hidden'}} id='refreshRoute' color="inherit" onClick={() => this.addRoute('normalized')}></Button>*/}
        <Button
          style={{ visibility: "hidden" }}
          id="Streets"
          color="inherit"
          onClick={() => this.updateBaseLayer("streets-v12")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Satellite"
          color="inherit"
          onClick={() => this.updateBaseLayer("satellite-v9")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Hybrid"
          color="inherit"
          onClick={() => this.updateBaseLayer("satellite-streets-v12")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="Navigation"
          color="inherit"
          onClick={() => this.updateBaseLayer("navigation-day-v1")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="LINK_SELECTED"
          color="inherit"
          onClick={() => this.linkSelected("")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="normalizedRoute"
          color="inherit"
          onClick={() => this.changeView("normalizedRoute")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="defaultRoute"
          color="inherit"
          onClick={() => this.changeView("route")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="zoomToBounds"
          color="inherit"
          onClick={() => this.zoomToBounds()}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="startLocation"
          color="inherit"
          onClick={() => this.watchForStartLocation()}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="destinationBtn"
          color="inherit"
          onClick={() => this.watchForDestination()}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="clearMarkersBtn"
          color="inherit"
          onClick={() => this.clearMarkers()}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="resizeMapBtn"
          color="inherit"
          onClick={() => this.resizeMap()}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="route1Toggle"
          color="inherit"
          onClick={() => this.toggleMapLayer("route")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="route2Toggle"
          color="inherit"
          onClick={() => this.toggleMapLayer("route2")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="route3Toggle"
          color="inherit"
          onClick={() => this.toggleMapLayer("route3")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="normalizedRoute1Toggle"
          color="inherit"
          onClick={() => this.toggleMapLayer("normalizedRoute")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="normalizedRoute2Toggle"
          color="inherit"
          onClick={() => this.toggleMapLayer("normalizedRoute2")}
        ></Button>
        <Button
          style={{ visibility: "hidden" }}
          id="normalizedRoute3Toggle"
          color="inherit"
          onClick={() => this.toggleMapLayer("normalizedRoute3")}
        ></Button>
        <div id="BOUND" data-bound="123">
          {" "}
        </div>
      </div>
    );
  }

  //start add route 1
  addRoute(route: any) {
    // console.log(route);

    //clearing out the previous route, then adding the new one
    //this removes routes 1, 2, and 3 for cases where just this single route is loaded
    if (this.map.getLayer("route")) {
      this.map.removeLayer("route");
      this.map.removeSource("route");
    }

    if (this.map.getLayer("normalizedRoute")) {
      this.map.removeLayer("normalizedRoute");
      this.map.removeSource("normalizedRoute");
    }

    if (this.map.getLayer("route2")) {
      this.map.removeLayer("route2");
      this.map.removeSource("route2");
    }

    if (this.map.getLayer("normalizedRoute2")) {
      this.map.removeLayer("normalizedRoute2");
      this.map.removeSource("normalizedRoute2");
    }

    if (this.map.getLayer("route3")) {
      this.map.removeLayer("route3");
      this.map.removeSource("route3");
    }

    if (this.map.getLayer("normalizedRoute3")) {
      this.map.removeLayer("normalizedRoute3");
      this.map.removeSource("normalizedRoute3");
    }

    this.map.addSource("route", { type: "geojson", data: route });
    this.map.addSource("normalizedRoute", { type: "geojson", data: route });

    if (this.props.routes.route1Checked) {
      if (this.view == "route") {
        this.map.addLayer({
          id: "route",
          source: "route",
          type: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6,
          },
        });
        this.map.addLayer({
          id: "normalizedRoute",
          source: "normalizedRoute",
          type: "line",
          layout: {
            visibility: "none",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "normalized_color"],
            "line-width": 6,
          },
        });
      }
      if (this.view == "normalizedRoute") {
        this.map.addLayer({
          id: "normalizedRoute",
          source: "normalizedRoute",
          type: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "normalized_color"],
            "line-width": 6,
          },
        });
        this.map.addLayer({
          id: "route",
          source: "route",
          type: "line",
          layout: {
            visibility: "none",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6,
          },
        });
      }
    } else {
      this.map.addLayer({
        id: "route",
        source: "route",
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "color"],
          "line-width": 6,
        },
      });
      this.map.addLayer({
        id: "normalizedRoute",
        source: "normalizedRoute",
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "normalized_color"],
          "line-width": 6,
        },
      });
    }

    const riskLookupTable: {
      [key: number]: { description: string; label: string };
    } = {
      0: {
        description: "no risk features of concern were found in this area.",
        label: "No Concerns",
      },
      1: {
        description:
          "a single low risk feature was found in this area that has a risk score between 2 and 3.",
        label: "Very Low Concern",
      },
      2: {
        description:
          "multiple low risk features were found in this area that have risk scores between 2 and 3.",
        label: "Low Concern",
      },
      3: {
        description:
          "a single undesirable risk feature was found in this area that has a risk score between 4 and 6.",
        label: "Moderate Concern",
      },
      4: {
        description:
          "multiple risk features were found in this area and at least one has an undesirable risk score between 4 and 6.",
        label: "High Concern",
      },
      5: {
        description:
          "one or more highly undesirable risk features were found in this area that have a risk score between 7 and 9.",
        label: "Very High Concern",
      },
      6: {
        description:
          "at least one no-go risk feature was found in this area that has a risk score between 9 and 10.",
        label: "No Go",
      },
    };

    // Function to get description by key
    function getDescription(key: any): string {
      return riskLookupTable[key]?.description || "Invalid key";
    }

    // Function to get label by key
    function getLabel(key: any): string {
      return riskLookupTable[key]?.label || "Invalid key";
    }

    //Route Hover over
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      maxWidth: "400px",
    });
    this.map.on("mouseenter", "route", (e: any) => {
      this.map.getCanvas().style.cursor = "pointer";

      const coordinates = e.lngLat;
      let milesRounded = parseFloat(e.features[0].properties.miles).toFixed(2);
      let properties = e.features[0].properties;
      const tableHTML = `
        <table border="1" cellpadding="1" cellspacing="1" style="width:100%; border-collapse: collapse; font-size: 12px;">
          <tr>
            <th>Level</th>
            <th>Description</th>
            <th>Simple Label</th>
          </tr>
          <tr>
            <td>0</td>
            <td>No risk features of concern (no features or score <=1) on this segment</td>
            <td>No Concerns</td>
          </tr>
          <tr>
            <td>1</td>
            <td>A single low risk (score >= 2 and <= 3) feature exists on this segment.</td>
            <td>Very Low Concern</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Multiple low risk (score >= 2 and <= 3) features exist on this segment.</td>
            <td>Low Concern</td>
          </tr>
          <tr>
            <td>3</td>
            <td>A single undesirable (score >=4 and <= 6) feature exists on this segment.</td>
            <td>Moderate Concern</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Multiple features exist including at least one undesirable (score between >=4 and <=6) feature.</td>
            <td>High Concern</td>
          </tr>
          <tr>
            <td>5</td>
            <td>One or more highly undesirable (score >= 7 and <=9) risk features exist on this segment.</td>
            <td>Very High Concern</td>
          </tr>
          <tr>
            <td>6</td>
            <td>At least one no-go risk feature exists on this segment (Score >9 and <= 10)</td>
            <td>No Go</td>
          </tr>
        </table>
      `;

      let explanationObject = properties.explanation;
      let explanationLength = 0;
      if (properties.explanation != "No risk found for this segment. ") {
        explanationObject = JSON.parse(properties.explanation);
        let keys = Object.keys(explanationObject);
        explanationLength = keys.length;
      }
      var bridgeOverwaterScore = explanationObject["bridge_overwater"] || 0;
      var bridgeOverLandScore = explanationObject["bridge_overland"] || 0;
      var bridgeOverpassScore = explanationObject["bridge_overpass"] || 0;
      var railScore = explanationObject["rail"] || 0;
      var tunnelScore = explanationObject["tunnel"] || 0;

      let riskFeatures: string[] = [];
      if (bridgeOverwaterScore !== 0) {
        riskFeatures.push("Bridge Over Water");
      }
      if (bridgeOverLandScore !== 0) {
        riskFeatures.push("Bridge Over Land");
      }
      if (bridgeOverpassScore !== 0) {
        riskFeatures.push("Bridge Overpass");
      }
      if (railScore !== 0) {
        riskFeatures.push("Rail");
      }
      if (tunnelScore !== 0) {
        riskFeatures.push("Tunnel");
      }

      let riskFeaturesString = riskFeatures.join(", ");

      let html = "";
      let isNonZeroValue = false;

      if (bridgeOverwaterScore !== 0) {
        html += `</br><strong style="font-size: 16px;">Bridge Over Water Score: ${bridgeOverwaterScore}</strong>`;
        isNonZeroValue = true;
      }
      if (bridgeOverLandScore !== 0) {
        html += `</br><strong style="font-size: 16px;">Bridge Over Land Score: ${bridgeOverLandScore}</strong>`;
        isNonZeroValue = true;
      }
      if (bridgeOverpassScore !== 0) {
        html += `</br><strong style="font-size: 16px;">Bridge Overpass Score: ${bridgeOverpassScore}</strong>`;
        isNonZeroValue = true;
      }
      if (railScore !== 0) {
        html += `</br><strong style="font-size: 16px;">Rail Crossing Score: ${railScore}</strong>`;
        isNonZeroValue = true;
      }
      if (tunnelScore !== 0) {
        html += `</br><strong style="font-size: 16px;">Tunnel Score: ${tunnelScore}</strong>`;
        isNonZeroValue = true;
      }

      if (isNonZeroValue) {
        html += "<br/>";
      }

      const description =
        `
        <strong style="font-size: 20px;">Risk Category ` +
        properties.risk +
        " (" +
        getLabel(properties.risk) +
        `)</strong>
        <hr class="solid">
        ` +
        html +
        `
        </br><strong style="font-size: 15px;">What does a risk category ` +
        properties.risk +
        ` mean?</strong>
        </br><a style="font-size: 14px;">A risk category ` +
        properties.risk +
        ` means that ` +
        getDescription(properties.risk) +
        `</a>
      `;

      // </br></br><strong style="font-size: 16px;">What makes this segment a category ` +
      // properties.risk +
      // `?</strong>
      // </br><a style="font-size: 14px;">This segment has ` + explanationLength + ` risk feature(s) (`+ riskFeaturesString +`)</a>

      // const description = `
      //   <strong style="font-size: 20px;">Risk Category 4 (High Concern) </strong>
      //   <hr class="solid">
      //   <strong style="font-size: 16px;">Bridge Overpass Score: 1</strong>
      //   </br><strong style="font-size: 16px;">Bridge Underpass Score: 2</strong>
      //   </br><strong style="font-size: 16px;">Rail Score: 5</strong>
      //   </br></br><strong style="font-size: 16px;">What does a risk category 4 mean?</strong>
      //   </br><a style="font-size: 12px;">A risk category 4 means that multiple risk features were found in this area and at least one has an undesirable risk score between 4 and 6.</a>
      //   </br></br><strong style="font-size: 16px;">What makes this segment a category 4?</strong>
      //   </br><a style="font-size: 12px;">This segment has 3 risk features (Overpass, Population, and Rail). The feature Rail has a risk category of 5 resulting in a category 4.</a>
      // `;
      // </br></br>${tableHTML}

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on("mouseenter", "normalizedRoute", (e: any) => {
      this.map.getCanvas().style.cursor = "pointer";

      const coordinates = e.lngLat;
      let milesRounded = parseFloat(e.features[0].properties.miles).toFixed(2);
      let properties = e.features[0].properties;
      const description =
        '<strong style="font-size: 14px;">Vulnerability Score: ' +
        properties.vulnerability_score_normalized +
        "%</strong>" +
        '<hr class="solid">' +
        "<strong>(Percentile Of All Route Segments)</strong>" +
        // + '<strong>Delays: </strong>' + properties.delays_normalized
        "</br><strong>Cell Coverage (" +
        properties.cell_provider +
        "): </strong>" +
        properties.cell_pct_covered_normalized +
        "%</br><strong>Incidents: </strong>" +
        properties.incidents_normalized +
        "%</br><strong>Population: </strong>" +
        properties.pop_normalized +
        "%</br><strong>Rail Crossings: </strong>" +
        properties.rail_junctions_normalized +
        "%</br><strong>Out of Service Area: </strong>" +
        properties.out_of_service_area_normalized +
        "%</br><strong>Tunnels: </strong>" +
        properties.tunnels_normalized +
        "%</br><strong>Weather: </strong>" +
        properties.weather_normalized +
        "%</br><strong>Wildfires: </strong>" +
        properties.wildfires_normalized +
        "%";

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on("mouseleave", "route", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
    });

    this.map.on("mouseleave", "normalizedRoute", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
    });

    console.log(route.features);
    this.getBoundsFromFeatures(route.features);
    var bounds = this.getBoundsFromFeatures(route.features);
    this.mapBounds = bounds;
    this.map.fitBounds(bounds, { padding: 100 });
  }
  //end add route 1

  //start add route 2
  addRoute2(route2: any) {
    // console.log(route);

    //clearing out the previous route, then adding the new one
    if (this.map.getLayer("route2")) {
      this.map.removeLayer("route2");
      this.map.removeSource("route2");
    }

    if (this.map.getLayer("normalizedRoute2")) {
      this.map.removeLayer("normalizedRoute2");
      this.map.removeSource("normalizedRoute2");
    }

    this.map.addSource("route2", { type: "geojson", data: route2 });
    this.map.addSource("normalizedRoute2", { type: "geojson", data: route2 });

    if (this.props.routes.route2Checked) {
      if (this.view == "route") {
        this.map.addLayer({
          id: "route2",
          source: "route2",
          type: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6,
          },
        });
        this.map.addLayer({
          id: "normalizedRoute2",
          source: "normalizedRoute2",
          type: "line",
          layout: {
            visibility: "none",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "normalized_color"],
            "line-width": 6,
          },
        });
      }
      if (this.view == "normalizedRoute") {
        this.map.addLayer({
          id: "normalizedRoute2",
          source: "normalizedRoute2",
          type: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "normalized_color"],
            "line-width": 6,
          },
        });
        this.map.addLayer({
          id: "route2",
          source: "route2",
          type: "line",
          layout: {
            visibility: "none",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6,
          },
        });
      }
    } else {
      this.map.addLayer({
        id: "route2",
        source: "route2",
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "color"],
          "line-width": 6,
        },
      });
      this.map.addLayer({
        id: "normalizedRoute2",
        source: "normalizedRoute2",
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "normalized_color"],
          "line-width": 6,
        },
      });
    }

    //Route Hover over
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    this.map.on("mouseenter", "route2", (e: any) => {
      this.map.getCanvas().style.cursor = "pointer";

      const coordinates = e.lngLat;
      let milesRounded = parseFloat(e.features[0].properties.miles).toFixed(2);
      let properties = e.features[0].properties;
      const description =
        '<strong style="font-size: 14px;">Vulnerability Score: ' +
        properties.vulnerability_score +
        "</strong>" +
        '<hr class="solid">' +
        // + '<strong>Delays: </strong>' + properties.delays
        "<strong>Cell Coverage (" +
        properties.cell_provider +
        "): </strong>" +
        properties.cell_pct_covered +
        "</br><strong>Incidents: </strong>" +
        properties.incidents +
        "</br><strong>Population: </strong>" +
        properties.pop +
        "</br><strong>Rail Crossings: </strong>" +
        properties.rail_junctions +
        "</br><strong>Out of Service Area: </strong>" +
        properties.out_of_service_area +
        "</br><strong>Tunnels: </strong>" +
        properties.tunnels +
        "</br><strong>Weather: </strong>" +
        properties.weather +
        "</br><strong>Wildfires: </strong>" +
        properties.wildfires;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on("mouseenter", "normalizedRoute2", (e: any) => {
      this.map.getCanvas().style.cursor = "pointer";

      const coordinates = e.lngLat;
      let milesRounded = parseFloat(e.features[0].properties.miles).toFixed(2);
      let properties = e.features[0].properties;
      const description =
        '<strong style="font-size: 14px;">Vulnerability Score: ' +
        properties.vulnerability_score_normalized +
        "%</strong>" +
        '<hr class="solid">' +
        "<strong>(Percentile Of All Route Segments)</strong>" +
        // + '<strong>Delays: </strong>' + properties.delays_normalized
        "</br><strong>Cell Coverage (" +
        properties.cell_provider +
        "): </strong>" +
        properties.cell_pct_covered_normalized +
        "%</br><strong>Incidents: </strong>" +
        properties.incidents_normalized +
        "%</br><strong>Population: </strong>" +
        properties.pop_normalized +
        "%</br><strong>Rail Crossings: </strong>" +
        properties.rail_junctions_normalized +
        "%</br><strong>Out of Service Area: </strong>" +
        properties.out_of_service_area_normalized +
        "%</br><strong>Tunnels: </strong>" +
        properties.tunnels_normalized +
        "%</br><strong>Weather: </strong>" +
        properties.weather_normalized +
        "%</br><strong>Wildfires: </strong>" +
        properties.wildfires_normalized +
        "%";

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on("mouseleave", "route2", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
    });

    this.map.on("mouseleave", "normalizedRoute2", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
    });

    // var bounds = this.getBoundsFromFeatures(route2.features);
    // this.mapBounds = bounds;
    // this.map.fitBounds(bounds, { padding: 99 });
  }
  //end add route 2

  //start add route 3
  addRoute3(route3: any) {
    // console.log(route);

    //clearing out the previous route, then adding the new one
    if (this.map.getLayer("route3")) {
      this.map.removeLayer("route3");
      this.map.removeSource("route3");
    }

    if (this.map.getLayer("normalizedRoute3")) {
      this.map.removeLayer("normalizedRoute3");
      this.map.removeSource("normalizedRoute3");
    }

    this.map.addSource("route3", { type: "geojson", data: route3 });
    this.map.addSource("normalizedRoute3", { type: "geojson", data: route3 });

    if (this.props.routes.route3Checked) {
      if (this.view == "route") {
        this.map.addLayer({
          id: "route3",
          source: "route3",
          type: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6,
          },
        });
        this.map.addLayer({
          id: "normalizedRoute3",
          source: "normalizedRoute3",
          type: "line",
          layout: {
            visibility: "none",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "normalized_color"],
            "line-width": 6,
          },
        });
      }
      if (this.view == "normalizedRoute") {
        this.map.addLayer({
          id: "normalizedRoute3",
          source: "normalizedRoute3",
          type: "line",
          layout: {
            visibility: "visible",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "normalized_color"],
            "line-width": 6,
          },
        });
        this.map.addLayer({
          id: "route3",
          source: "route3",
          type: "line",
          layout: {
            visibility: "none",
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": ["get", "color"],
            "line-width": 6,
          },
        });
      }
    } else {
      this.map.addLayer({
        id: "route3",
        source: "route3",
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "color"],
          "line-width": 6,
        },
      });
      this.map.addLayer({
        id: "normalizedRoute3",
        source: "normalizedRoute3",
        type: "line",
        layout: {
          visibility: "none",
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": ["get", "normalized_color"],
          "line-width": 6,
        },
      });
    }

    //Route Hover over
    const popup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
    });
    this.map.on("mouseenter", "route3", (e: any) => {
      this.map.getCanvas().style.cursor = "pointer";

      const coordinates = e.lngLat;
      let milesRounded = parseFloat(e.features[0].properties.miles).toFixed(2);
      let properties = e.features[0].properties;
      const description =
        '<strong style="font-size: 14px;">Vulnerability Score: ' +
        properties.vulnerability_score +
        "</strong>" +
        '<hr class="solid">' +
        // + '<strong>Delays: </strong>' + properties.delays
        "<strong>Cell Coverage (" +
        properties.cell_provider +
        "): </strong>" +
        properties.cell_pct_covered +
        "</br><strong>Incidents: </strong>" +
        properties.incidents +
        "</br><strong>Population: </strong>" +
        properties.pop +
        "</br><strong>Rail Crossings: </strong>" +
        properties.rail_junctions +
        "</br><strong>Out of Service Area: </strong>" +
        properties.out_of_service_area +
        "</br><strong>Tunnels: </strong>" +
        properties.tunnels +
        "</br><strong>Weather: </strong>" +
        properties.weather +
        "</br><strong>Wildfires: </strong>" +
        properties.wildfires;

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on("mouseenter", "normalizedRoute3", (e: any) => {
      this.map.getCanvas().style.cursor = "pointer";

      const coordinates = e.lngLat;
      let milesRounded = parseFloat(e.features[0].properties.miles).toFixed(2);
      let properties = e.features[0].properties;
      const description =
        '<strong style="font-size: 14px;">Vulnerability Score: ' +
        properties.vulnerability_score_normalized +
        "%</strong>" +
        '<hr class="solid">' +
        "<strong>(Percentile Of All Route Segments)</strong>" +
        // + '<strong>Delays: </strong>' + properties.delays_normalized
        "</br><strong>Cell Coverage (" +
        properties.cell_provider +
        "): </strong>" +
        properties.cell_pct_covered_normalized +
        "%</br><strong>Incidents: </strong>" +
        properties.incidents_normalized +
        "%</br><strong>Population: </strong>" +
        properties.pop_normalized +
        "%</br><strong>Rail Crossings: </strong>" +
        properties.rail_junctions_normalized +
        "%</br><strong>Out of Service Area: </strong>" +
        properties.out_of_service_area_normalized +
        "%</br><strong>Tunnels: </strong>" +
        properties.tunnels_normalized +
        "%</br><strong>Weather: </strong>" +
        properties.weather_normalized +
        "%</br><strong>Wildfires: </strong>" +
        properties.wildfires_normalized +
        "%";

      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      popup.setLngLat(coordinates).setHTML(description).addTo(this.map);
    });

    this.map.on("mouseleave", "route3", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
    });

    this.map.on("mouseleave", "normalizedRoute3", () => {
      this.map.getCanvas().style.cursor = "";
      popup.remove();
    });

    // var bounds = this.getBoundsFromFeatures(route3.features);
    // this.mapBounds = bounds;
    // this.map.fitBounds(bounds, { padding: 99 });
  }
  //end add route 3

  linkSelected(e: any) {
    let bounds = document.getElementById("BOUND")!.getAttribute("data-bound");
    // const bbox = [-79, 43, -73, 45];
    const bbox = bounds?.split(",");
    this.map.fitBounds(bbox, { padding: 100 });
  }
}

const mapStateToProps = (state: any) => ({
  linkIds: state.highlightedRoute.linkIds,
  route: state.route,
  route2: state.route2,
  route3: state.route3,
  routes: state.routes,
  token: state.token,
  lineStringCoordinates: selectLineStringCoordinates(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  setLinkIds: (linkIds: any) => dispatch(setLinkIds(linkIds)),
  setRoute: (route: any) => dispatch(setRoute(route)),
  setRoute2: (route2: any) => dispatch(setRoute2(route2)),
  setRoute3: (route3: any) => dispatch(setRoute3(route3)),
});

// export default TodoList;
export default connect(mapStateToProps, mapDispatchToProps)(Map);
