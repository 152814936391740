import { useEffect } from "react";

import { StackedBarChartTemplate } from "./ChartTemplates";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import Button from "@material-ui/core/Button";
import { getRoute } from "../../redux/reducers/uploadedRouteSlice";
import { getRoute2 } from "../../redux/reducers/uploadedRouteSlice";
import { getRoute3 } from "../../redux/reducers/uploadedRouteSlice";
import { getChart } from "../../redux/reducers/chartDisplaySlice";

const colors = [
  "#702963",
  // "#a6cee3",
  "#1f78b4",
  // "#b2df8a",
  "#33a02c",
  "#fb9a99",
  // "#e31a1c",
  // "#fdbf6f",
  "#ff7f00",
  // "#cab2d6",
  // "#6a3d9a",
  // "#ffff99",
  "#b15928",
];
// const risk_factors = ["delays", "incidents", "pop", "rail_junctions", "safe_havens", "tunnels", "vulnerability_score", "weather", "wildfires"]
const risk_factors = [
  "risk",
  "bridge_overwater",
  "bridge_overland",
  // "bridge_underpass",
  "bridge_overpass",
  "rail",
  "tunnel",
];
const risk_factors_match: any = {
  risk: "Risk Category",
  bridge_overwater: "Bridge Over Water",
  bridge_overland: "Bridge Over Land",
  // bridge_underpass: "Bridge Underpass",
  bridge_overpass: "Bridge Overpass",
  rail: "Rail Crossing",
  tunnel: "Tunnels",
};
const risk_factors_legend = [
  "Risk Category",
  "Bridge Over Water",
  // "Bridge Underpass",
  "Bridge Over Land",
  "Bridge Overpass",
  "Rail Crossing",
  "Tunnels",
];

interface RISKS {
  // delays: string;
  risk: string;
  bridge_overwater: string;
  bridge_overland: string;
  // bridge_underpass: string;
  bridge_overpass: string;
  rail: string;
  tunnel: string;
}

function RiskStackedBarChart() {
  const loadedRoute = useAppSelector(getRoute);
  const loadedRoute2 = useAppSelector(getRoute2);
  const loadedRoute3 = useAppSelector(getRoute3);
  const chart = useAppSelector(getChart);

  // const dispatch = useAppDispatch();
  // const highlightedRouteLinkIds = useAppSelector(getLinkIds);

  // useEffect(() => {

  // }, [dispatch]);

  var risk_data;
  let route;
  if (chart == 0) {
    route = loadedRoute;
  }
  if (chart == 1) {
    route = loadedRoute;
  }
  if (chart == 2) {
    route = loadedRoute2;
  }
  if (chart == 3) {
    route = loadedRoute3;
  }
  // var route = loadedRoute // Temporary route data

  //Replacing cell coverage legend and risk_factors_match based on what provider is selected by user
  // let cellProvider = "Any";
  // if (loadedRoute.features.length >= 1) {
  //   if (loadedRoute.features[0].properties.cell_provider) {
  //     cellProvider = loadedRoute.features[0].properties.cell_provider;
  //   }
  //   risk_factors_legend[0] = "Cell Coverage (" + cellProvider + ")";
  //   delete risk_factors_match["cell_pct_covered"];
  //   risk_factors_match["cell_pct_covered"] = risk_factors_legend[0];
  // }

  //Need to loop through route.features, pull out specific values and put them in this format:
  // properties:{
  //   bridge_overwater: 3
  //   bridge_overwater: 2
  //   bridge_overpass: 0
  //   rail: 2
  //   risk: 3
  //   tunnel: 0
  //   explanation: "text"
  //   }

  // var route = TestRoute // Temporary route data
  // let features: any = [];
  // console.log(route.features[0].properties)
  // risk_data = route.features.map((d: any, i: Number) => {
  //   let risks: any = {};
  //   features.push(d);
  //   function gen(obj: RISKS) {
  //     for (const [key, value] of Object.entries(obj)) {
  //       // console.log(key);
  //       let isFound = risk_factors.some((d) => {
  //         if (d === key) {
  //           return true;
  //         }
  //       });

  //       if (isFound) {
  //         risks[risk_factors_match[key]] = +value;
  //       }
  //     }
  //   }
  //   gen(d.properties);
  //   risks["name"] = i.toString();
  //   // console.log(risks);
  //   return risks;
  // });

  //updated function using new attributes
  let features: any = [];
  console.log(route.features[0].properties);

  const addedNames = new Set();

  risk_data = route.features
    .map((d: any, i: Number) => {
      let risks: any = {};
      features.push(d);

      function gen(obj: RISKS) {
        for (const [key, value] of Object.entries(obj)) {
          // Handle nested explanation object
          if (key === "explanation") {
            for (const [subKey, subValue] of Object.entries(value)) {
              let isFound = risk_factors.some((d) => d === subKey);
              if (isFound) {
                risks[risk_factors_match[subKey]] = (subValue as number[])[0]; // Assuming subValue is an array and we need the first element
              }
            }
          } else {
            // Handle other keys at the top level
            let isFound = risk_factors.some((d) => d === key);
            if (isFound) {
              risks[risk_factors_match[key]] = +value;
            }
          }
        }
      }

      gen(d.properties);
      risks["name"] = i.toString();

      // Check if the name already exists in the Set
      if (addedNames.has(risks["name"])) {
        return null; // Skip this entry
      }

      // Add the name to the Set
      addedNames.add(risks["name"]);

      // console.log(risks);
      return risks;
    })
    .filter((risks:any) => risks !== null); // Filter out null values

  function updateRoute(route: any) {
    // route = TestRoute // Temporary route data
    risk_data = route.features.map((d: any, i: Number) => {
      return {
        name: i.toString(),
        delays: d.properties.delays,
        incidents: d.properties.incidents,
      };
    });
  }

  const divStyle = {
    height: 300,
    paddingLeft: "10%",
  };

  return (
    <div style={divStyle}>
      <StackedBarChartTemplate
        data={risk_data}
        xAxis="name"
        yAxis=""
        risks={risk_factors_legend}
        colors={colors}
        features={features}
        lineKey="Risk Category"
      />
      <Button
        style={{ visibility: "hidden" }}
        id="update_route"
        color="inherit"
        onClick={() => updateRoute("update_route")}
      ></Button>
    </div>
  );
}

export { RiskStackedBarChart };
