import { useState } from "react";

import { useAppDispatch } from "../../redux/hooks";
import { setLinkIds } from "../../redux/reducers/highlightedRouteSlice";
import mapboxgl from "mapbox-gl";

import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Cell,
  AreaChart,
  Area,
  Legend,
  ComposedChart,
  ResponsiveContainer,
} from "recharts";

interface ChartPropsInterface {
  data: any;
  xAxis: string;
  yAxis: string;
  lineKey: string;
}

interface ChartPropsInterface2 {
  data: any;
  xAxis: string;
  yAxis: string;
  risks: any;
  colors: any;
  features: any;
  lineKey: string;
}

const chartWidth: number = 1300;
const chartHeight: number = 300;
const chartMargins: any = {
  top: 5,
  right: 20,
  left: 20,
  bottom: 5,
};

function AreaChartTemplate(props: ChartPropsInterface) {
  return (
    <AreaChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="population"
        stackId="1"
        stroke="#8884d8"
        fill="#8884d8"
      />
      <Area
        type="monotone"
        dataKey="police"
        stackId="1"
        stroke="#82ca9d"
        fill="#82ca9d"
      />
    </AreaChart>
  );
}

function MultiLineChartTemplate(props: ChartPropsInterface) {
  return (
    <LineChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis dataKey={props.yAxis} />
      <Tooltip />
      {/* Need to fix the codes below*/}
      <Line type="monotone" dataKey="population" stroke="#8884d8" />
      <Line type="monotone" dataKey="police" stroke="#82ca9d" />
    </LineChart>
  );
}

function LineChartTemplate(props: ChartPropsInterface) {
  return (
    <LineChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis dataKey={props.yAxis} />
      <Tooltip />
      <Line type="monotone" dataKey={props.lineKey} stroke="#82ca9d" />
    </LineChart>
  );
}

function StackedBarChartTemplate(props: ChartPropsInterface2) {
  function renderBars() {
    var colors = props.colors;

    const bars = props.risks
      .filter((risk: string) => {
        if (risk === "Risk Category") return false;
        return true;
      })
      .map((d: string, i: number) => {
        return (
          <Bar
            yAxisId="left"
            dataKey={d}
            stackId="a"
            fill={colors[i]}
            onClick={onBarClick}
          />
        );
      });
    return bars;
  }

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Risk Category: ${props.features[label].risk}`}</p>
          {/* <p className="intro">{getIntroOfPage(label)}</p> */}
          {/* <p className="desc">Anything you want can be displayed here.</p> */}
        </div>
      );
    }

    return null;
  };

  function onBarClick(e: any) {
    // alert(props.properties[+e.name].id)
    // document.getElementById("BOUND")!.setAttribute("data-bound", props.features[+e.name])
    // console.log(e);

    var coords: any = [];
    //using index for line dot, name for bar
    // if (e.activeLabel || e.activeLabel == 0) {
    //   coords = coords.concat(
    //     ...props.features[+e.activeLabel].geometry.coordinates,
    //   );
    // }
    if (e.activeLabel || e.activeLabel === 0) {
      const coordinates = props.features[+e.activeLabel].geometry.coordinates;
      coordinates.forEach((coordinate:any) => {
        coords.push(coordinate);
      });
    }
    if (e.index || e.index == 0) {
      // coords = coords.concat(...props.features[+e.index].geometry.coordinates);
      const coordinates = props.features[+e.index].geometry.coordinates;
      coordinates.forEach((coordinate:any) => {
        coords.push(coordinate);
      });
    }
    if (e.name) {
      // coords = coords.concat(...props.features[+e.name].geometry.coordinates);
      const coordinates = props.features[+e.name].geometry.coordinates;
      coordinates.forEach((coordinate:any) => {
        coords.push(coordinate);
      });
    }

    // Create a 'LngLatBounds' with both corners at the first coordinate.
    const bounds = new mapboxgl.LngLatBounds(coords[0], coords[0]);

    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    for (const coord of coords) {
      bounds.extend(coord);
    }
    // console.log(coords)
    // var bounds = coords.reduce(
    //   function (bounds: any, coord: any) {
    //     return bounds.extend(coord);
    //   },
    //   new mapboxgl.LngLatBounds(coords[0], coords[0]),
    // );

    const bbox = `${bounds.getWest()}, ${bounds.getSouth()}, ${bounds.getEast()}, ${bounds.getNorth()}`;
    // [west, south, east, north]
    document.getElementById("BOUND")!.setAttribute("data-bound", bbox);
    document.getElementById("LINK_SELECTED")!.click();
  }

  return (
    <ResponsiveContainer
      box-sizing="border-box"
      width="90%"
      // height='99%'
      // aspect={5}
      debounce={1}
      // background-color='#fff'
    >
      <ComposedChart
        onClick={onBarClick}
        // <BarChart
        // width={chartWidth}
        // height={chartHeight}
        data={props.data}
        margin={chartMargins}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={props.xAxis} />
        <YAxis yAxisId="left" />
        {/* <YAxis yAxisId="right" orientation="right" /> */}
        <Tooltip />
        {/* <Tooltip content={<CustomTooltip />} /> */}
        <Legend />
        {renderBars()}
        <Line
          yAxisId="left"
          type="monotone"
          dataKey={props.lineKey}
          stroke="#000000"
          dot={{ r: 3 }}
          activeDot={{ onClick: (event, payload) => onBarClick(payload) }}
        />
        {/* <Bar dataKey="pv" stackId="a" fill="#8884d8" /> */}
        {/* <Bar dataKey="uv" stackId="a" fill="#82ca9d" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  );
}

function BarChartTemplate(props: ChartPropsInterface) {
  const dispatch = useAppDispatch();
  const [activeIds] = useState<Set<number>>(new Set());
  const [mouseIsClicked, setMouseIsClicked] = useState(false);

  function mouseDown(e: any) {
    setMouseIsClicked(true);
  }

  function mouseUp(e: any) {
    setMouseIsClicked(false);
    dispatch(setLinkIds(Array.from(activeIds)));
    activeIds.clear();
  }

  function mouseMove(e: any) {
    mouseIsClicked && activeIds.add(e.activePayload[0].payload.linkid);
  }

  return (
    <BarChart
      width={chartWidth}
      height={chartHeight}
      data={props.data}
      margin={chartMargins}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseMove={mouseMove}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey={props.xAxis} />
      <YAxis dataKey={props.yAxis} />
      <Tooltip />
      <Bar dataKey={props.lineKey}>
        {props.data.map(function (entry: any, index: number) {
          let color = entry.selected ? "#ff3838" : "#82ca9d";
          return <Cell key={`cell-${index}`} fill={color} stroke={color} />;
        })}
      </Bar>
    </BarChart>
  );
}

export {
  LineChartTemplate,
  BarChartTemplate,
  MultiLineChartTemplate,
  AreaChartTemplate,
  StackedBarChartTemplate,
};
export type { ChartPropsInterface };
