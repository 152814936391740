import mapboxgl from "mapbox-gl";

interface Waypoint {
  lng: number;
  lat: number;
}

export class WaypointDropper implements mapboxgl.IControl {
  private map: mapboxgl.Map | null = null;
  private waypoints: Waypoint[] = [];
  private markers: mapboxgl.Marker[] = [];
  private addWaypointMode: boolean = false;
  private container: HTMLElement;
  private addButton: HTMLButtonElement;
  private generateRouteButton: HTMLButtonElement;
  private clearAllButton: HTMLButtonElement;
  private infoButton: HTMLButtonElement | null = null;
  private popupContainer: HTMLDivElement | null = null;

  constructor() {
    this.container = document.createElement("div");
    this.container.className = "mapboxgl-ctrl";

    this.generateRouteButton = document.createElement("button");
    this.generateRouteButton.id = "generate-route-button";
    this.generateRouteButton.textContent = "GENERATE ROUTE";
    this.generateRouteButton.style.backgroundColor = "#3F51B5";
    this.generateRouteButton.style.color = "white";
    this.generateRouteButton.style.padding = "6px 6px";
    this.generateRouteButton.style.border = "none";
    this.generateRouteButton.style.borderRadius = "5px";
    this.generateRouteButton.style.cursor = "pointer";
    this.generateRouteButton.style.marginRight = "5px";
    this.generateRouteButton.style.lineHeight = "20px";
    this.generateRouteButton.onclick = () => this.generateRoute();

    this.addButton = document.createElement("button");
    this.addButton.id = "add-geocoder-button";
    this.addButton.textContent = "+ ADD WAYPOINT";
    this.addButton.style.backgroundColor = "#3F51B5";
    this.addButton.style.color = "white";
    this.addButton.style.padding = "6px 6px";
    this.addButton.style.border = "none";
    this.addButton.style.borderRadius = "5px";
    this.addButton.style.cursor = "pointer";
    this.addButton.style.marginRight = "5px";
    this.addButton.style.lineHeight = "20px";
    this.addButton.title =
      "Add a waypoint to the map. Any selected highway will be preferred when generating your route.";
    this.addButton.onclick = () => this.toggleAddWaypointMode();

    this.clearAllButton = document.createElement("button");
    this.clearAllButton.id = "clear-all-waypoints-button";
    this.clearAllButton.textContent = "CLEAR ALL";
    this.clearAllButton.style.backgroundColor = "#3F51B5";
    this.clearAllButton.style.color = "white";
    this.clearAllButton.style.padding = "6px 6px";
    this.clearAllButton.style.border = "none";
    this.clearAllButton.style.borderRadius = "5px";
    this.clearAllButton.style.cursor = "pointer";
    this.clearAllButton.style.lineHeight = "20px";
    this.clearAllButton.onclick = () => this.clearAllWaypoints();

    this.infoButton = document.createElement("button");
    this.infoButton.id = "info-button";
    this.infoButton.innerHTML = "ℹ️";
    this.infoButton.style.backgroundColor = "transparent";
    this.infoButton.style.color = "#3F51B5";
    this.infoButton.style.padding = "6px";
    this.infoButton.style.border = "none";
    this.infoButton.style.borderRadius = "50%";
    this.infoButton.style.cursor = "pointer";
    this.infoButton.addEventListener(
      "mouseenter",
      this.showInfoPopup.bind(this)
    );
    this.infoButton.addEventListener(
      "mouseleave",
      this.hideInfoPopup.bind(this)
    );

    this.container.appendChild(this.generateRouteButton);
    this.container.appendChild(this.addButton);
    this.container.appendChild(this.clearAllButton);
    this.container.appendChild(this.infoButton);
  }

  onAdd(map: mapboxgl.Map) {
    this.map = map;
    this.map.on("click", this.onMapClick.bind(this));
    this.loadWaypoints();
    return this.container;
  }

  onRemove() {
    if (this.container.parentNode) {
      this.container.parentNode.removeChild(this.container);
    }
    if (this.map) {
      this.map.off("click", this.onMapClick.bind(this));
      this.map = null;
    }
    this.hideInfoPopup(); // Ensure the popup is hidden when removing
  }

  private toggleAddWaypointMode() {
    if (this.map) {
      this.addWaypointMode = !this.addWaypointMode;
      this.map.getCanvas().style.cursor = this.addWaypointMode
        ? "crosshair"
        : "";
    }
  }

  private onMapClick(event: mapboxgl.MapMouseEvent & mapboxgl.EventData) {
    if (this.addWaypointMode && this.map) {
      const lngLat = event.lngLat;
      this.addWaypoint(lngLat.lng, lngLat.lat);
      this.toggleAddWaypointMode();
    }
  }

  private addWaypoint(lng: number, lat: number) {
    if (this.map) {
      const waypoint = { lng, lat };
      const marker = new mapboxgl.Marker({ draggable: false })
        .setLngLat([lng, lat])
        .addTo(this.map);

      marker.getElement().addEventListener("click", () => {
        this.removeWaypoint(marker, waypoint);
      });

      this.markers.push(marker);
      this.waypoints.push(waypoint);
      this.saveWaypoints();
    }
  }

  private removeWaypoint(marker: mapboxgl.Marker, waypoint: Waypoint) {
    if (this.map) {
      // Remove the marker from the map and markers array
      marker.remove();
      this.markers = this.markers.filter((m) => m !== marker);

      // Remove the waypoint from the waypoints array
      this.waypoints = this.waypoints.filter((wp) => wp !== waypoint);

      // Update local storage
      this.saveWaypoints();
    }
  }

  private saveWaypoints() {
    localStorage.setItem("waypoints", JSON.stringify(this.waypoints));
  }

  private loadWaypoints() {
    if (this.map) {
      const savedWaypoints = localStorage.getItem("waypoints");
      if (savedWaypoints) {
        this.waypoints = JSON.parse(savedWaypoints);
        this.waypoints.forEach((waypoint) => {
          const marker = new mapboxgl.Marker({ draggable: false })
            .setLngLat([waypoint.lng, waypoint.lat])
            .addTo(this.map!);

          marker.getElement().addEventListener("click", () => {
            this.removeWaypoint(marker, waypoint);
          });

          this.markers.push(marker);
        });
      }
    }
  }

  private clearAllWaypoints() {
    if (this.map) {
      // Remove only the markers created by this WaypointDropper
      this.markers.forEach((marker) => marker.remove());
      this.markers = []; // Clear the markers array

      // Clear waypoints array and local storage
      this.waypoints = [];
      this.saveWaypoints();
    }
  }

  private showInfoPopup() {
    if (this.map && this.infoButton) {
      const buttonRect = this.infoButton.getBoundingClientRect();

      // Create a container for the popup
      this.popupContainer = document.createElement("div");
      this.popupContainer.className = "custom-popup-container";
      this.popupContainer.innerHTML = `
        <div class="custom-popup">
          <h4>Specify preferred highways by adding waypoints along your route. If the route does not meet your expectations, adding additional waypoints can help improve accuracy.</h4>
        </div>
      `;

      // Append the popup container to the map's container
      this.map.getContainer().appendChild(this.popupContainer);

      // Position the popup container
      this.updatePopupPosition();

      // Add event listeners to adjust the position if the layout changes
      window.addEventListener("resize", this.updatePopupPosition.bind(this));
      window.addEventListener("scroll", this.updatePopupPosition.bind(this));
    }
  }

  private updatePopupPosition() {
    if (this.popupContainer && this.infoButton && this.map) {
      const buttonRect = this.infoButton.getBoundingClientRect();
      const mapRect = this.map.getContainer().getBoundingClientRect();
      const offsetX = 40; // Horizontal offset from the button
      const offsetY = 25; // Vertical offset from the button

      this.popupContainer.style.position = "absolute";
      this.popupContainer.style.left = `${
        buttonRect.left - mapRect.left + window.scrollX + offsetX
      }px`;
      this.popupContainer.style.top = `${
        buttonRect.bottom - mapRect.top + window.scrollY - offsetY
      }px`;
      this.popupContainer.style.zIndex = "1000"; // Ensure it appears above other content
    }
  }

  private hideInfoPopup() {
    if (this.popupContainer) {
      // Remove event listeners
      window.removeEventListener("resize", this.updatePopupPosition.bind(this));
      window.removeEventListener("scroll", this.updatePopupPosition.bind(this));
      this.popupContainer.remove();
      this.popupContainer = null; // Clear the reference
    }
  }

  private generateRoute() {
    console.log("Generate route function called");
    document.getElementById("generateRoute")!.click();
  }
}
